import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { Btn } from "../../../../../AbstractElements";
import CardHeaderCommon from "../../../../../CommonElements/CardHeaderCommon/CardHeaderCommon";
import { useDispatch, useSelector } from "react-redux";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { patchRequest } from "../../../../../utils/axiosRequests";
import { useState } from "react";

interface PasswordInput {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

const ChangePassword = () => {
  const userInfo = useSelector((state: AppState) => state.auth.data);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<PasswordInput>({});

  const onSubmit: SubmitHandler<PasswordInput> = (data: any) => {
    const { password, currentPassword } = data;
    patchRequest(`auth/change-password/${userInfo?.id}`, {
      password,
      currentPassword,
    })
      .then((res) => {
        toast.success("Successfully updated", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <Col xl="4">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardHeaderCommon
            title={"Change Password"}
            tagClass={"card-title mb-0"}
          />
          <CardBody>
            <FormGroup>
              <Label>Change Password</Label>
              <Input
                bsSize="sm"
                type="password"
                {...register("currentPassword", {
                  required: true,
                })}
                onChange={(e) => {
                  setValue("currentPassword", e.target.value);
                }}
              />
              {errors?.password?.type === "required" && (
                <span className="error-msg">This field is required</span>
              )}
            </FormGroup>
            <FormGroup>
              <Label>Password</Label>
              <Input
                bsSize="sm"
                type="password"
                {...register("password", {
                  required: true,
                  minLength: {
                    value: 5,
                    message: "Value must be at least 5 characters long",
                  },
                  maxLength: {
                    value: 15,
                    message: "Value must be maximum length 20",
                  },
                })}
                onChange={(e) => {
                  setValue("password", e.target.value);
                }}
              />
              {errors?.password?.type === "required" && (
                <span className="error-msg">This field is required</span>
              )}
              {errors?.password && (
                <span className="error-msg">{errors?.password?.message}</span>
              )}
            </FormGroup>
            <FormGroup>
              <Label>Retype Password</Label>
              <Input
                bsSize="sm"
                type="password"
                {...register("confirmPassword", {
                  required: true,
                  minLength: {
                    value: 5,
                    message: "Value must be at least 5 characters long",
                  },
                  maxLength: {
                    value: 15,
                    message: "Value must be maximum length 20",
                  },
                })}
                onChange={(e) => {
                  setValue("confirmPassword", e.target.value);
                }}
              />
              {errors?.password?.type === "required" && (
                <span className="error-msg">This field is required</span>
              )}
              {errors?.password && (
                <span className="error-msg">{errors?.password?.message}</span>
              )}
            </FormGroup>
            <FormGroup>
              <Btn
                color="primary"
                type="submit"
                className="btn-sm"
                onClick={handleSubmit(onSubmit)}
              >
                Update
              </Btn>
            </FormGroup>
          </CardBody>
          {/* <CardFooter className="text-end">
            <Btn color="primary" type="submit">
              Update
            </Btn>
          </CardFooter> */}
        </Card>
      </Form>
    </Col>
  );
};

export default ChangePassword;
