import { MenuItem } from "../../Types/Layout/SidebarType";

export const MenuList: MenuItem[] = [
  {
    title: "সনদ",
    lanClass: "lan-1",
    Items: [
      {
        id: 1,
        path: `${process.env.PUBLIC_URL}/dashboard`,
        icon: "home",
        type: "link",
        active: false,
        title: "হোম",
      },
      {
        title: "সনদ",
        id: 2,
        icon: "file",
        type: "sub",
        lanClass: "lan-3",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/certificate/list`,
            title: "সনদ লিস্ট",
            type: "link",
            lanClass: "lan-4",
          },
          {
            path: `${process.env.PUBLIC_URL}/certificate/create`,
            title: "সনদ তৈরী",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    title: "রিপোর্ট",
    lanClass: "lan-1",
    Items: [
      {
        title: "রিপোর্ট",
        id: 2,
        icon: "file",
        type: "sub",
        lanClass: "lan-3",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/certificate/report`,
            title: "সনদ রিপোর্ট",
            type: "link",
            lanClass: "lan-4",
          },
        ],
      },
    ],
  },
  {
    title: "সেটিংস",
    lanClass: "lan-1",
    Items: [
      {
        title: "সেটিংস",
        id: 3,
        icon: "knowledgebase",
        type: "sub",
        lanClass: "lan-3",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/settings/unions`,
            title: "ইউনিয়ন লিস্ট",
            type: "link",
            lanClass: "lan-4",
          },
          {
            path: `${process.env.PUBLIC_URL}/settings/users`,
            title: "ইউজার লিস্ট",
            type: "link",
            lanClass: "lan-4",
          },
        ],
      },
    ],
  },
];
