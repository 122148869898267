import { Badges, H5, LI, SVG } from "../../../../AbstractElements";
import { Messages } from "../../../../utils/Constant";
import MessageBox from "./MessageBox";

const HeaderMessage = () => {
  return (
    <></>
    // <LI className="onhover-dropdown">
    //   <div className="notification-box">
    //     <SVG iconId={'header-message'} />
    //     <Badges pill color="info">3 </Badges>
    //   </div>
    //   <div className="onhover-show-div notification-dropdown">
    //     <H5 className="f-18 f-w-600 mb-0 dropdown-title">{Messages} </H5>
    //     <MessageBox />
    //   </div>
    // </LI>
  );
};

export default HeaderMessage;
