import LoginWithSweetAlertContainer from '../../../../Componant/OtherPages/Authentication/LoginWithSweetAlert/LoginWithSweetAlert'

const LoginWithSweetAlert = () => {
  return (
    <div className='page-body'>
      <LoginWithSweetAlertContainer />
    </div>
  )
}

export default LoginWithSweetAlert