import RegisterSimpleContainer from '../../../../Componant/OtherPages/Authentication/RegisterSimple/RegisterSimple'

const RegisterSimple = () => {
  return (
    <div className='page-body'>
      <RegisterSimpleContainer />
    </div>
  )
}

export default RegisterSimple