import { H5, LI, SVG } from "../../../../AbstractElements";
import { Cart } from "../../../../utils/Constant";
import CartBox from "./CartBox";

const HeaderCart = () => {
  return (
    <></>
    // <LI className="cart-nav onhover-dropdown">
    //   <div className="cart-box">
    //     <SVG iconId='stroke-ecommerce'/>
    //   </div>
    //   <div className="cart-dropdown onhover-show-div">
    //     <H5 className="f-18 f-w-600 mb-0 dropdown-title">{Cart}</H5>
    //     <CartBox />
    //   </div>
    // </LI>
  );
};

export default HeaderCart;
