import { LI, UL } from '../../../AbstractElements'

const CommonUL = () => {
  return (
    <>
      <UL className="flex-row simple-list">
        <LI></LI>
        <LI></LI>
        <LI></LI>
      </UL>
    </>
  )
}

export default CommonUL