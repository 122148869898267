import React from "react";
import moment from "moment";
import { Row, Col } from "reactstrap";
import parse from "html-react-parser";
import { englishToBanglaNumber } from "../../../utils/helpers";

const TradeLicence = ({ certificate, tranlation }) => {
  const { details } = certificate || {};
  const taxAndFeeRows = () => {
    return (
      details?.taxesAndFees?.length &&
      details?.taxesAndFees[0]?.item !== "" &&
      details?.taxesAndFees?.map((data, key) => {
        //const sl = ++key.toString();
        return (
          <tr key={key}>
            <td>{englishToBanglaNumber((++key).toString())}</td>
            <td>{data.item}</td>
            <td>{englishToBanglaNumber(data.amount)}</td>
          </tr>
        );
      })
    );
  };

  let totalFee =
    details?.taxesAndFees?.length > 0 &&
    details?.taxesAndFees.reduce((accumulator, currentValue) => {
      return (
        accumulator +
        (currentValue.amount !== "" ? parseInt(currentValue.amount) : 0)
      );
    }, 0);

  return (
    <>
      <Row className="mt-2 mb-2 certificate-row trade-licenc-title">
        <Col md={3}>
          লাইসেন্স নং: {englishToBanglaNumber(certificate?.certificateNo)}
        </Col>
        {details?.financeYear && (
          <Col md={3}>
            অর্থ বছর: {englishToBanglaNumber(details?.financeYear)}
          </Col>
        )}
        {certificate?.issueDate && (
          <Col>
            ইস্যু তারিখ:{" "}
            {englishToBanglaNumber(
              moment(certificate?.issueDate).format("DD-MM-YYYY")
            )}
          </Col>
        )}
        {details?.validDate && (
          <Col>
            বৈধতার মেয়াদ:{" "}
            {englishToBanglaNumber(
              moment(details?.validDate).format("DD-MM-YYYY")
            )}
          </Col>
        )}
      </Row>
      <Row className="trade-certificate-note">
        <Col>{parse(certificate?.certificateNote)}</Col>
      </Row>
      {/* <h3>নাগরিক তথ্য:</h3> */}
      <Row className="trade-licence-top-area mt-1">
        <Col className="left-col">
          <Row>
            <Col sm={4}>প্রতিষ্ঠানের নাম</Col>
            <Col>
              <span className="fw-bold">:&nbsp;{details?.instituteName}</span>
            </Col>
          </Row>
          {details?.typeOfBusiness && (
            <Row>
              <Col sm={4}>ব্যবসার ধরন</Col>
              <Col>
                <span>:&nbsp;{details?.typeOfBusiness}</span>
              </Col>
            </Row>
          )}
          {details?.typeOfNature && (
            <Row>
              <Col sm={4}>Nature of Business</Col>
              <Col>
                <span>:&nbsp;{details?.typeOfNature}</span>
              </Col>
            </Row>
          )}
          {details?.tax_region && (
            <Row>
              <Col sm={4}>কর অঞ্চল</Col>
              <Col>
                <span>:&nbsp;{details?.tax_region}</span>
              </Col>
            </Row>
          )}
          {details?.ward_no && (
            <Row>
              <Col sm={4}>ওয়ার্ড নং</Col>
              <Col>
                <span>:&nbsp;{details?.ward_no}</span>
              </Col>
            </Row>
          )}
          {details?.capital && (
            <Row>
              <Col sm={4}>মূলধন</Col>
              <Col>
                <span>:&nbsp;{details?.capital}</span>
              </Col>
            </Row>
          )}
          {details?.instituteAddress && (
            <Row>
              <Col sm={4}>ব্যবসা প্রতিষ্ঠানের ঠিকানা</Col>
              <Col>
                <span>:&nbsp;{details?.instituteAddress}</span>
              </Col>
            </Row>
          )}
        </Col>
        <Col className="right-col">
          {details?.applicantName && (
            <Row>
              <Col md={4}>লাইসেন্সধারীর নাম</Col>
              <Col>
                <span className="fw-bold">: {details?.applicantName}</span>
              </Col>
            </Row>
          )}
          {details?.fathersName && (
            <Row>
              <Col md={4}>পিতার নাম</Col>
              <Col>
                <span>:&nbsp;{details?.fathersName}</span>
              </Col>
            </Row>
          )}
          {details?.mothersName && (
            <Row>
              <Col md={4}>মাতার নাম</Col>
              <Col>
                <span>:&nbsp;{details?.mothersName}</span>
              </Col>
            </Row>
          )}
          {details?.mobileNo && (
            <Row>
              <Col md={4}>মোবাইল/টেলিফোন</Col>
              <Col>
                <span>:&nbsp;{details?.mobileNo}</span>
              </Col>
            </Row>
          )}
          {details?.email && (
            <Row>
              <Col md={4}>ই-মেইল</Col>
              <Col>
                <span>:&nbsp;{details?.email}</span>
              </Col>
            </Row>
          )}
          {details?.nid_passport_birth_reg_no && (
            <Row>
              <Col md={4}>জাতীয় পরিচয়পত্র/পাসপোর্ট নম্বর</Col>
              <Col>
                <span>:&nbsp;{details?.nid_passport_birth_reg_no}</span>
              </Col>
            </Row>
          )}
          {details?.tin && (
            <Row>
              <Col md={4}>ই-টিন</Col>
              <Col>
                <span>:&nbsp;{details?.tin}</span>
              </Col>
            </Row>
          )}
          {details?.presentAddress && (
            <Row>
              <Col md={4}>বর্তমান ঠিকানা</Col>
              <Col>
                <span>:&nbsp;{details?.presentAddress}</span>
              </Col>
            </Row>
          )}
          {details?.permanentAddress && (
            <Row>
              <Col md={4}>স্থায়ী ঠিকানা</Col>
              <Col>
                <span>: {details?.permanentAddress}</span>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Row className="mb-1 mt-1">
        <Col>
          <b>ফি ও করের পরিমান:</b>
        </Col>
      </Row>
      <Row className="pb-4">
        <Col>
          <table className="list-table  my-0 trade-licence-table">
            <thead>
              <tr>
                <th>ক্রমিক নং</th>
                <th>আইটেমের নাম</th>
                <th>ফি</th>
              </tr>
            </thead>
            <tbody>{taxAndFeeRows()}</tbody>
            <tfoot>
              {details?.taxesAndFees?.length > 0 && (
                <tr className="total-row">
                  <td colSpan={2}>সর্বমোট</td>
                  <td className="text-center">
                    {totalFee
                      ? englishToBanglaNumber(totalFee?.toString())
                      : ""}
                  </td>
                </tr>
              )}
            </tfoot>
          </table>
        </Col>
      </Row>
    </>
  );
};

export default TradeLicence;
