import { Container, Row } from "reactstrap";
import EditMyProfile from "./EditMyProfile/EditMyProfile";
import EditProfileForm from "./EditProfileForm/EditProfileForm";
import ChangePassword from "./EditMyProfile/ChangePassword";

const EditProfileContainer = () => {
  return (
    <Container fluid>
      <div className="edit-profile">
        <Row>
          <EditMyProfile />
          <EditProfileForm />
          <ChangePassword />
        </Row>
      </div>
    </Container>
  );
};

export default EditProfileContainer;
