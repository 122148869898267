import ViewDataSourceContainer from "../../../../Componant/Tables/DataTables/DataSource/HtmlSourcedData/ViewCertificate";

const ViewCertificate = () => {
  return (
    <div className="page-body">
      <ViewDataSourceContainer />
    </div>
  );
};

export default ViewCertificate;
