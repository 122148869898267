import { Row, Col, Container } from "reactstrap";
import { Btn, H3 } from "../../AbstractElements";
import { useNavigate } from "react-router-dom";

const Support = () => {
  const navigate = useNavigate();

  const backToLogin = () => {
    navigate("/");
  };

  return (
    <Container fluid className="p-0 support-area">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card login-dark">
            <div>
              <div
                className="login-main text-center"
                style={{ width: "540px", paddingBottom: "15px" }}
              >
                <H3 className="f-w-600">
                  আপনার ব্যালেন্স শেষ হয়েছে। রিচার্জ করতে নিম্নের নাম্বারে
                  যোগাযোগ করুন
                </H3>
                <hr />
                <H3>মো: মিজানুর রহমান মিলন </H3>{" "}
                <H3>মোবাইল: 01733714009 (বিকাশ, নগদ)</H3>
                <H3>ইমেইল: mizanur090148@gmail.com</H3>
                <Btn color="primary" onClick={backToLogin}>
                  Back To Login
                </Btn>
                <div className="supported-company">
                  Supported by "Gentle Solutions"
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Support;
