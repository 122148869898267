import ComingWithBgVideoContainer from '../../../../Componant/OtherPages/ComingSoon/ComingWithBgVideo/ComingWithBgVideo'

const ComingWithBgVideo = () => {
  return (
    <div className='page-body'>
      <ComingWithBgVideoContainer />
    </div>
  )
}

export default ComingWithBgVideo