import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Btn } from "../../../../../AbstractElements";
import {
  AboutMe,
  Address,
  City,
  Company,
  Country,
  EditProfile,
  Emailaddress,
  FirstName,
  LastName,
  PostalCode,
  UpdateProfile,
  Username,
} from "../../../../../utils/Constant";
import CardHeaderCommon from "../../../../../CommonElements/CardHeaderCommon/CardHeaderCommon";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { signIn } from "../../../../../Store/Slices/AuthSlice";
import { patchRequest } from "../../../../../utils/axiosRequests";
import { useDispatch, useSelector } from "react-redux";

interface UserInput {
  email: string;
  mobile: string;
  name: string;
  address: string;
  aboutMe: string;
}

const EditProfileForm = () => {
  const userInfo = useSelector((state: AppState) => state.auth.data);
  const dispatch = useDispatch();

  const { email, mobile, name, address, aboutMe } = userInfo || {};
  const defaultValues = {
    email,
    mobile,
    name,
    address,
    aboutMe,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<UserInput>({ defaultValues });

  const onSubmit: SubmitHandler<UserInput> = (data: any) => {
    console.log(data, "datadata");
    patchRequest(`auth/${userInfo.id}`, data)
      .then((res) => {
        dispatch(signIn({ ...userInfo, ...data }));
        toast.success("Successfully updated", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Col xl="4">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardHeaderCommon title={EditProfile} tagClass={"card-title mb-0"} />
          <CardBody>
            <Row>
              <Col>
                <FormGroup className="edit-profile">
                  <Label>Name</Label>
                  <Input
                    bsSize="sm"
                    defaultValue={name}
                    {...register("name", {
                      required: true,
                      maxLength: {
                        value: 30,
                        message: "Value must be maximum length 30",
                      },
                    })}
                    onChange={(e) => {
                      setValue("name", e.target.value);
                    }}
                  />
                  {errors?.name?.type === "required" && (
                    <span className="error-msg">This field is required</span>
                  )}
                  {errors?.name && (
                    <span className="error-msg">{errors?.name?.message}</span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup className="edit-profile">
                  <Label>Email</Label>
                  <Input
                    bsSize="sm"
                    defaultValue={email}
                    {...register("email", {
                      required: true,
                      maxLength: {
                        value: 30,
                        message: "Maximum length 30",
                      },
                    })}
                    onChange={(e) => {
                      setValue("email", e.target.value);
                    }}
                  />
                  {errors?.email?.type === "required" && (
                    <span className="error-msg">This field is required</span>
                  )}
                  {errors?.email && (
                    <span className="error-msg">{errors?.email?.message}</span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup className="edit-profile">
                  <Label>Mobile No.</Label>
                  <Input
                    bsSize="sm"
                    defaultValue={mobile}
                    {...register("mobile", {
                      maxLength: 30,
                      ...(watch("mobile") && { required: true }),
                    })}
                    onChange={(e) => {
                      setValue("mobile", e.target.value);
                    }}
                  />
                  {errors?.mobile && (
                    <span className="error-msg">{errors?.mobile?.message}</span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup className="edit-profile">
                  <Label>Address</Label>
                  <Input
                    type="textarea"
                    bsSize="sm"
                    defaultValue={address}
                    {...register("address", {
                      maxLength: 130,
                      ...(watch("address") && { required: true }),
                    })}
                    onChange={(e) => {
                      setValue("address", e.target.value);
                    }}
                  />
                  {errors?.address && (
                    <span className="error-msg">
                      {errors?.address?.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup className="edit-profile">
                  <Label>About Me</Label>
                  <Input
                    type="textarea"
                    bsSize="sm"
                    defaultValue={aboutMe}
                    {...register("aboutMe", {
                      maxLength: 150,
                      ...(watch("aboutMe") && { required: true }),
                    })}
                    onChange={(e) => {
                      setValue("aboutMe", e.target.value);
                    }}
                  />
                  {errors?.aboutMe && (
                    <span className="error-msg">
                      {errors?.aboutMe?.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <FormGroup>
                  <Btn
                    color="primary"
                    type="submit"
                    className="btn-sm"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Update
                  </Btn>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          {/* <CardFooter className="text-end">
            <Btn color="primary" type="submit">
              {UpdateProfile}
            </Btn>
          </CardFooter> */}
        </Card>
      </Form>
    </Col>
  );
};

export default EditProfileForm;
