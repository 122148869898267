import InputGroupContainer from '../../../../Componant/Forms/FormsControl/InputGroup/InputGroup'

const InputGroup = () => {
  return (
    <div className='page-body'>
      <InputGroupContainer />
    </div>
  )
}

export default InputGroup