import React, { memo } from "react";
import { sonodTitle } from "../../../utils/helpers";

const CertificateTitle = ({ type, tranlation }) => {
  return (
    <div className="sonod-title mt-1">
      {tranlation === "ENG" ? "Trade Licence" : sonodTitle(type)}
    </div>
  );
};

export default memo(CertificateTitle);
