import { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { H4, Image, P } from "../../../AbstractElements";
import { dynamicImage } from "../../../Service";
import CardHeaderCommon from "../../../CommonElements/CardHeaderCommon/CardHeaderCommon";
import { capitalizeFirst } from "../../../utils/helpers";
import { useSelector } from "react-redux";
import { getRequest } from "../../../utils/axiosRequests";
import moment from "moment";

const PaymentHistory = () => {
  const userInfo = useSelector((state: AppState) => state.auth.data);
  const [paymentHistories, setPaymentHistories] = useState([]);

  const getPaymentHistory = async () => {
    await getRequest(`settings/users/payment-histories?userId=${userInfo.id}`)
      .then((data) => {
        setPaymentHistories(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getPaymentHistory();
  }, []);

  return (
    <Col xl="8">
      <Card>
        <CardHeaderCommon
          title={"Payment History"}
          tagClass="card-title mb-0"
        />
        <CardBody>
          <Row className="mb-2">
            <div className="balance-history-title">Histories:</div>
            <table className="list-table table-hover my-0">
              <thead>
                <th>SL.</th>
                <th>Amount</th>
                <th>Payment Date</th>
              </thead>
              <tbody>
                {paymentHistories.length > 0 &&
                  paymentHistories.map((payment: any, index: any) => (
                    <tr key={payment.addDate}>
                      <td>{++index}</td>
                      <td>{payment?.amount}</td>
                      <td>{moment(payment.addDate).format("YY-MM-DD")}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Row>
          <hr />
        </CardBody>
      </Card>
    </Col>
  );
};

export default PaymentHistory;
