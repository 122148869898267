import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Form, Input, Label, Row } from "reactstrap";
import { patchRequest } from "../../../utils/axiosRequests";
import { Btn } from "../../../AbstractElements";

interface UnionInputs {
  fees: {
    citizenship: number;
    character: number;
    trade_licence: number;
    inheritance: number;
    death: number;
    unemployment: number;
    unmarried: number;
    married: number;
    certification: number;
    widow: number;
    new_voter: number;
    voter_transfer: number;
    parent_permission: number;
    monthly_income: number;
    yearly_income: number;
    non_objection: number;
    farm_worker: number;
    voter_correction: number;
    marriage_without_dowry: number;
  };
}

const CertificatesFeeForm = () => {
  const location = useLocation();
  const union = location?.state?.union;
  const unionId = union?.id;
  const [fees, setfees] = useState(union?.fees);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<UnionInputs>({ defaultValues: union });

  const onSubmit: SubmitHandler<UnionInputs> = (feesData: any) => {
    patchRequest(`settings/unions/${unionId}`, {
      ...union,
      ...feesData,
    })
      .then((res) => {
        toast.success("Successfully updated", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="mb-2">
        <Col md="4" className="position-relative">
          <Label>নাগরিকত্ব সনদ:</Label>
          <Input
            bsSize="sm"
            type="number"
            defaultValue={fees?.citizenship}
            className={`${errors?.fees?.citizenship ? "is-invalid" : ""}`}
            {...register("fees.citizenship", {
              maxLength: {
                value: 15,
                message: "Value must not exceed length 15",
              },
            })}
            onChange={(e) => {
              setValue("fees.citizenship", parseInt(e.target.value));
            }}
          />
          {errors?.fees?.citizenship && (
            <span className="error-msg">
              {errors?.fees?.citizenship?.message}
            </span>
          )}
        </Col>
        <Col md="4" className="position-relative">
          <Label>চারিত্রিক সনদ:</Label>
          <Input
            bsSize="sm"
            type="number"
            className={`${errors?.fees?.character ? "is-invalid" : ""}`}
            defaultValue={fees?.character}
            {...register("fees.character", {
              maxLength: {
                value: 15,
                message: "Value must not exceed length 15",
              },
            })}
            onChange={(e) => {
              setValue("fees.character", parseInt(e.target.value));
            }}
          />
          {errors?.fees?.character && (
            <span className="error-msg">
              {errors?.fees?.character?.message}
            </span>
          )}
        </Col>
        <Col md="4" className="position-relative">
          <Label>ট্রেড লাইসেন্স:</Label>
          <Input
            bsSize="sm"
            type="number"
            className={`${errors?.fees?.trade_licence ? "is-invalid" : ""}`}
            defaultValue={fees?.trade_licence}
            {...register("fees.trade_licence", {
              maxLength: {
                value: 15,
                message: "Value must not exceed length 15",
              },
            })}
            onChange={(e) => {
              setValue("fees.trade_licence", parseInt(e.target.value));
            }}
          />
          {errors?.fees?.trade_licence && (
            <span className="error-msg">
              {errors?.fees?.trade_licence?.message}
            </span>
          )}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md="4" className="position-relative">
          <Label>উত্তরাধিকার সনদ:</Label>
          <Input
            bsSize="sm"
            type="number"
            className={`${errors?.fees?.inheritance ? "is-invalid" : ""}`}
            defaultValue={fees?.inheritance}
            {...register("fees.inheritance", {
              maxLength: {
                value: 15,
                message: "Value must not exceed length 15",
              },
            })}
            onChange={(e) => {
              setValue("fees.inheritance", parseInt(e.target.value));
            }}
          />
          {errors?.fees?.inheritance && (
            <span className="error-msg">
              {errors?.fees?.inheritance?.message}
            </span>
          )}
        </Col>
        <Col md="4" className="position-relative">
          <Label>মৃত্যু সনদ:</Label>
          <Input
            bsSize="sm"
            type="number"
            className={`${errors?.fees?.death ? "is-invalid" : ""}`}
            defaultValue={fees?.death}
            {...register("fees.death", {
              maxLength: {
                value: 15,
                message: "Value must not exceed length 15",
              },
            })}
            onChange={(e) => {
              setValue("fees.death", parseInt(e.target.value));
            }}
          />
          {errors?.fees?.death && (
            <span className="error-msg">{errors?.fees?.death?.message}</span>
          )}
        </Col>
        <Col md="4" className="position-relative">
          <Label>বেকারত্ব সনদ:</Label>
          <Input
            bsSize="sm"
            type="number"
            className={`${errors?.fees?.unemployment ? "is-invalid" : ""}`}
            defaultValue={fees?.unemployment}
            {...register("fees.unemployment", {
              maxLength: {
                value: 15,
                message: "Value must not exceed length 15",
              },
            })}
            onChange={(e) => {
              setValue("fees.unemployment", parseInt(e.target.value));
            }}
          />
          {errors?.fees?.unemployment && (
            <span className="error-msg">
              {errors?.fees?.unemployment?.message}
            </span>
          )}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md="4" className="position-relative">
          <Label>অবিবাহিত সনদ:</Label>
          <Input
            bsSize="sm"
            type="number"
            className={`${errors?.fees?.unmarried ? "is-invalid" : ""}`}
            defaultValue={fees?.unmarried}
            {...register("fees.unmarried", {
              maxLength: {
                value: 15,
                message: "Value must not exceed length 15",
              },
            })}
            onChange={(e) => {
              setValue("fees.unmarried", parseInt(e.target.value));
            }}
          />
          {errors?.fees?.unmarried && (
            <span className="error-msg">
              {errors?.fees?.unmarried?.message}
            </span>
          )}
        </Col>
        <Col md="4" className="position-relative">
          <Label>বিবাহিত সনদ:</Label>
          <Input
            bsSize="sm"
            type="number"
            className={`${errors?.fees?.married ? "is-invalid" : ""}`}
            defaultValue={fees?.married}
            {...register("fees.married", {
              maxLength: {
                value: 15,
                message: "Value must not exceed length 15",
              },
            })}
            onChange={(e) => {
              setValue("fees.married", parseInt(e.target.value));
            }}
          />
          {errors?.fees?.married && (
            <span className="error-msg">{errors?.fees?.married?.message}</span>
          )}
        </Col>
        <Col md="4" className="position-relative">
          <Label>প্রত্যয়ন পত্র:</Label>
          <Input
            bsSize="sm"
            type="number"
            className={`${errors?.fees?.certification ? "is-invalid" : ""}`}
            defaultValue={fees?.certification}
            {...register("fees.certification", {
              maxLength: {
                value: 15,
                message: "Value must not exceed length 15",
              },
            })}
            onChange={(e) => {
              setValue("fees.certification", parseInt(e.target.value));
            }}
          />
          {errors?.fees?.certification && (
            <span className="error-msg">
              {errors?.fees?.certification?.message}
            </span>
          )}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md="4" className="position-relative">
          <Label>বিধবা সনদ:</Label>
          <Input
            bsSize="sm"
            type="number"
            className={`${errors?.fees?.widow ? "is-invalid" : ""}`}
            defaultValue={fees?.widow}
            {...register("fees.widow", {
              maxLength: {
                value: 15,
                message: "Value must not exceed length 15",
              },
            })}
            onChange={(e) => {
              setValue("fees.widow", parseInt(e.target.value));
            }}
          />
          {errors?.fees?.widow && (
            <span className="error-msg">{errors?.fees?.widow?.message}</span>
          )}
        </Col>
        <Col md="4" className="position-relative">
          <Label>নতুন ভোটার সনদ:</Label>
          <Input
            bsSize="sm"
            type="number"
            className={`${errors?.fees?.new_voter ? "is-invalid" : ""}`}
            defaultValue={fees?.new_voter}
            {...register("fees.new_voter", {
              maxLength: {
                value: 15,
                message: "Value must not exceed length 15",
              },
            })}
            onChange={(e) => {
              setValue("fees.new_voter", parseInt(e.target.value));
            }}
          />
          {errors?.fees?.new_voter && (
            <span className="error-msg">
              {errors?.fees?.new_voter?.message}
            </span>
          )}
        </Col>
        <Col md="4" className="position-relative">
          <Label>ভোটার এলাকা স্থানান্তর সনদ:</Label>
          <Input
            bsSize="sm"
            type="number"
            className={`${errors?.fees?.voter_transfer ? "is-invalid" : ""}`}
            defaultValue={fees?.voter_transfer}
            {...register("fees.voter_transfer", {
              maxLength: {
                value: 15,
                message: "Value must not exceed length 15",
              },
            })}
            onChange={(e) => {
              setValue("fees.voter_transfer", parseInt(e.target.value));
            }}
          />
          {errors?.fees?.voter_transfer && (
            <span className="error-msg">
              {errors?.fees?.voter_transfer?.message}
            </span>
          )}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md="4" className="position-relative">
          <Label>অভিভাবক সম্মতি সনদ:</Label>
          <Input
            bsSize="sm"
            type="number"
            className={`${errors?.fees?.parent_permission ? "is-invalid" : ""}`}
            defaultValue={fees?.parent_permission}
            {...register("fees.parent_permission", {
              maxLength: {
                value: 15,
                message: "Value must not exceed length 15",
              },
            })}
            onChange={(e) => {
              setValue("fees.parent_permission", parseInt(e.target.value));
            }}
          />
          {errors?.fees?.parent_permission && (
            <span className="error-msg">
              {errors?.fees?.parent_permission?.message}
            </span>
          )}
        </Col>
        <Col md="4" className="position-relative">
          <Label>মাসিক আয়ের সনদ:</Label>
          <Input
            bsSize="sm"
            type="number"
            className={`${errors?.fees?.monthly_income ? "is-invalid" : ""}`}
            defaultValue={fees?.monthly_income}
            {...register("fees.monthly_income", {
              maxLength: {
                value: 15,
                message: "Value must not exceed length 15",
              },
            })}
            onChange={(e) => {
              setValue("fees.monthly_income", parseInt(e.target.value));
            }}
          />
          {errors?.fees?.monthly_income && (
            <span className="error-msg">
              {errors?.fees?.monthly_income?.message}
            </span>
          )}
        </Col>
        <Col md="4" className="position-relative">
          <Label>বার্ষিক আয়ের সনদ:</Label>
          <Input
            bsSize="sm"
            type="number"
            className={`${errors?.fees?.yearly_income ? "is-invalid" : ""}`}
            defaultValue={fees?.yearly_income}
            {...register("fees.yearly_income", {
              maxLength: {
                value: 15,
                message: "Value must not exceed length 15",
              },
            })}
            onChange={(e) => {
              setValue("fees.yearly_income", parseInt(e.target.value));
            }}
          />
          {errors?.fees?.yearly_income && (
            <span className="error-msg">
              {errors?.fees?.yearly_income?.message}
            </span>
          )}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md="4" className="position-relative">
          <Label>অনাপত্তি পত্র:</Label>
          <Input
            bsSize="sm"
            type="number"
            className={`${errors?.fees?.non_objection ? "is-invalid" : ""}`}
            defaultValue={fees?.non_objection}
            {...register("fees.non_objection", {
              maxLength: {
                value: 15,
                message: "Value must not exceed length 15",
              },
            })}
            onChange={(e) => {
              setValue("fees.non_objection", parseInt(e.target.value));
            }}
          />
          {errors?.fees?.non_objection && (
            <span className="error-msg">
              {errors?.fees?.non_objection?.message}
            </span>
          )}
        </Col>
        <Col md="4" className="position-relative">
          <Label>খামারি প্রত্যয়ন পত্র:</Label>
          <Input
            bsSize="sm"
            type="number"
            className={`${errors?.fees?.farm_worker ? "is-invalid" : ""}`}
            defaultValue={fees?.farm_worker}
            {...register("fees.farm_worker", {
              maxLength: {
                value: 15,
                message: "Value must not exceed length 15",
              },
            })}
            onChange={(e) => {
              setValue("fees.farm_worker", parseInt(e.target.value));
            }}
          />
          {errors?.fees?.farm_worker && (
            <span className="error-msg">
              {errors?.fees?.farm_worker?.message}
            </span>
          )}
        </Col>
        <Col md="4" className="position-relative">
          <Label>ভোটার সংশোধনের প্রত্যয়ন পত্র:</Label>
          <Input
            bsSize="sm"
            type="number"
            className={`${errors?.fees?.voter_correction ? "is-invalid" : ""}`}
            defaultValue={fees?.voter_correction}
            {...register("fees.voter_correction", {
              maxLength: {
                value: 15,
                message: "Value must not exceed length 15",
              },
            })}
            onChange={(e) => {
              setValue("fees.voter_correction", parseInt(e.target.value));
            }}
          />
          {errors?.fees?.voter_correction && (
            <span className="error-msg">
              {errors?.fees?.voter_correction?.message}
            </span>
          )}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md="4" className="position-relative">
          <Label>যৌতুক বিহীন সনদ:</Label>
          <Input
            bsSize="sm"
            type="number"
            className={`${
              errors?.fees?.marriage_without_dowry ? "is-invalid" : ""
            }`}
            defaultValue={fees?.marriage_without_dowry}
            {...register("fees.marriage_without_dowry", {
              maxLength: {
                value: 15,
                message: "Value must not exceed length 15",
              },
            })}
            onChange={(e) => {
              setValue("fees.marriage_without_dowry", parseInt(e.target.value));
            }}
          />
          {errors?.fees?.marriage_without_dowry && (
            <span className="error-msg">
              {errors?.fees?.marriage_without_dowry?.message}
            </span>
          )}
        </Col>
      </Row>
      <Row className="mt-3 mb-1">
        <Col sm="12" className="text-center">
          <Btn
            size="sm"
            color="primary"
            type="submit"
            onClick={() => handleSubmit(onSubmit)}
          >
            UPDATE
          </Btn>
        </Col>
      </Row>
    </Form>
  );
};

export default CertificatesFeeForm;
