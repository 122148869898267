import ContainerReport from "../../../Componant/Report/ReportContainer";

const CertificateReport = () => {
  return (
    <div className="page-body">
      <ContainerReport />
    </div>
  );
};

export default CertificateReport;
