import Error400Container from '../../../../Componant/OtherPages/Error/Error400/Error400'

const Error400 = () => {
  return (
    <div className='page-body'>
      <Error400Container />
    </div>
  )
}

export default Error400