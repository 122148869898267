import RegisterWithImageTwoContainer from '../../../../Componant/OtherPages/Authentication/RegisterWithImageTwo/RegisterWithImageTwo'

const RegisterWithImageTwo = () => {
  return (
    <div className='page-body'>
      <RegisterWithImageTwoContainer />
    </div>
  )
}

export default RegisterWithImageTwo