import { Container, Row } from "reactstrap";
import EditMyProfile from "../EditProfile/EditMyProfile/EditMyProfile";
import PaymentHistory from "../PaymentHistory";
import { useSelector } from "react-redux";

const UsersProfileContainer = () => {
  const userInfo = useSelector((state: AppState) => state.auth.data);

  return (
    <Container fluid>
      <div className="user-profile">
        <Row>
          <EditMyProfile />
          {userInfo?.userType === "user" && <PaymentHistory />}
        </Row>
      </div>
    </Container>
  );
};

export default UsersProfileContainer;
