import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Container, Form, FormGroup, Input, Label } from "reactstrap";
import { Btn, H3, P } from "../../AbstractElements";
import { EmailAddress, Href, Password } from "../../utils/Constant";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { postRequest } from "../../utils/axiosRequests";
import { signIn } from "../../Store/Slices/AuthSlice";

const Login = () => {
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitData = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    postRequest("auth/login", { username, password })
      .then((data: any) => {
        if (data.statusCode === 401) {
          navigate("/support");
        } else {
          sessionStorage.setItem("token", JSON.stringify(data.token));
          dispatch(signIn(data));
          navigate("/dashboard");
        }
      })
      .catch((error) => {
        setError(error.message);
        toast.error("Please Enter valid email or password...!");
      });
  };

  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card login-dark">
            <div>
              <div>
                <Link className="logo text-center" to={Href}>
                  {/* <Image
                    className="img-fluid for-light"
                    src={dynamicImage("logo/logo.png")}
                    alt="looginpage"
                  />
                  <Image
                    className="img-fluid for-dark"
                    src={dynamicImage("logo/logo_dark.png")}
                    alt="looginpage"
                  /> */}
                </Link>
              </div>
              <div className="login-main" style={{ paddingBottom: "25px" }}>
                <Form className="theme-form" onSubmit={(e) => submitData(e)}>
                  <H3>Sign in to your account to continue</H3>
                  <P>{"Enter your email & password to login"}</P>
                  {error && (
                    <div className="text-danger text-center">{error}</div>
                  )}
                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input
                      bsSize="sm"
                      type="email"
                      required
                      placeholder="Enter username"
                      value={username}
                      name="username"
                      onChange={(event) => setUsername(event.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{Password}</Label>
                    <div className="form-input position-relative">
                      <Input
                        bsSize="sm"
                        type={show ? "text" : "password"}
                        placeholder="*********"
                        onChange={(event) => setPassword(event.target.value)}
                        value={password}
                        name="password"
                      />
                      <div className="show-hide" onClick={() => setShow(!show)}>
                        <span className="show"> </span>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup className="mb-0 form-sub-title">
                    {/* <div className="checkbox p-0">
                      <Input id="checkbox1" type="checkbox" />
                      <Label className="text-muted" htmlFor="checkbox1">
                        {RememberPassword}
                      </Label>
                    </div>
                    <Link
                      to={`${process.env.PUBLIC_URL}/authentication/forget_password`}
                    >
                      {ForgotPassword}
                    </Link> */}
                    <div className="text-end mt-3">
                      <Btn color="primary" block className="w-100">
                        Submit
                      </Btn>
                    </div>
                  </FormGroup>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
