import ForgetPasswordContainer from '../../../../Componant/OtherPages/Authentication/ForgetPassword/ForgetPassword'

const ForgetPassword = () => {
  return (
    <div className='page-body'>
      <ForgetPasswordContainer />
    </div>
  )
}

export default ForgetPassword