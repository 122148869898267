import React, { memo } from "react";
import chairmanImg from "../../../images/general/chairman.png";

const CertificateVerify = ({ certificate }) => {
  return (
    <>
      <div className={`row chairman`}>
        <div className="col-sm-6"></div>
        <div className="col-sm-6 text-end chairman-area">
          <div className="chairman-info">
            <img src={chairmanImg} height={120} width={350} />
            {/* <div className="mb-2">________________________________________</div>
            <div style={{ fontSize: "16px", fontWeight: "bolder" }}>
              Md. Anwar Hossain Pinto
            </div>
            <div>
              <strong>Chairman</strong>
            </div>
            <div>Saturia Union Parishad</div>
            <div>Saturia, Manikganj</div> 
            */}
          </div>
        </div>
      </div>
      <div className="mb-2 row certificate-verify">
        <div className="col-sm-4">
          <div>সনদ যাচাই:</div>
          <div>ক) ভিজিট করুন: http://3.89.202.132/verify</div>
          <div>খ) কোড স্ক্যান করুন</div>
        </div>
        {/* <div className="col-sm-4">
          <div
            style={{
              height: "auto",
              margin: "0 auto",
              maxWidth: 64,
              width: "100%",
              textAlign: "center",
            }}
            className="qr-code"
          >
            {certificate && certificate?.certificateNo && (
              <QRCode
                size={256}
                style={{
                  height: "auto",
                  maxWidth: "100%",
                  width: "100%",
                  textAlign: "center",
                }}
                value={certificate?.certificateNo}
                viewBox={`0 0 256 256`}
              />
            )}
          </div>
        </div> */}
      </div>
    </>
  );
};

export default memo(CertificateVerify);
