import React, { memo } from "react";
import chairmanImg from "../../../images/general/chairman.png";
import chairmanEngImg from "../../../images/general/chairmanEng.png";

const CertificateVerifyTrade = ({ certificate, tranlation }) => {
  return (
    <>
      <div
        className={`row ${
          certificate?.type === 3 || certificate?.type === 4
            ? "chairman-trade"
            : "chairman"
        }`}
      >
        <div className="col-sm-6" style={{ textAlign: "center" }}>
          <div className="mb-2" style={{ paddingLeft: "30px" }}>
            <sapn>________________________________________</sapn>
            <div
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
                marginTop: "6px",
              }}
            >
              {tranlation === "BN"
                ? "প্রশাসনিক কর্মকর্তা"
                : "Administrative Officer"}
            </div>
            <div>
              {" "}
              {tranlation === "BN"
                ? certificate?.union?.name
                : "07 No. Saturia Union Parishad"}
            </div>
            <div>
              {tranlation === "BN"
                ? `${certificate?.union?.thana}, ${certificate?.union?.zilla}`
                : "Saturia, Manikganj"}
            </div>
          </div>
        </div>
        <div className="col-sm-6 text-end chairman-area">
          <div className="chairman-info">
            {tranlation === "ENG" ? (
              <img src={chairmanEngImg} height={120} width={350} />
            ) : (
              <img src={chairmanImg} height={120} width={350} />
            )}
          </div>
        </div>
      </div>
      <div className="mb-2 row certificate-verify">
        <div className="col-sm-4">
          <div>
            {tranlation === "ENG" ? "Certificate Verify" : "সনদ যাচাই"}:
          </div>
          <div>
            {tranlation === "ENG" ? "A) Visit" : "ক) ভিজিট করুন"} :
            http://3.89.202.132/verify
          </div>
          <div>
            {tranlation === "ENG" ? "B) Scan Code" : "খ) কোড স্ক্যান করুন"}
          </div>
        </div>
        {/* <div className="col-sm-4">
          <div
            style={{
              height: "auto",
              margin: "0 auto",
              maxWidth: 64,
              width: "100%",
              textAlign: "center",
            }}
            className="qr-code"
          >
            {certificate && certificate?.certificateNo && (
              <QRCode
                size={256}
                style={{
                  height: "auto",
                  maxWidth: "100%",
                  width: "100%",
                  textAlign: "center",
                }}
                value={certificate?.certificateNo}
                viewBox={`0 0 256 256`}
              />
            )}
          </div>
        </div> */}
      </div>
    </>
  );
};

export default memo(CertificateVerifyTrade);
