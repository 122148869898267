import RegisterWizardContainer from '../../../../Componant/OtherPages/Authentication/RegisterWizard/RegisterWizard'

const RegisterWizard = () => {
  return (
    <div className='page-body'>
      <RegisterWizardContainer />
    </div>
  )
}

export default RegisterWizard