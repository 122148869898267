import UserList from "../../../Componant/Tables/Settings/Users"


const Users = () => {
  return (
    <div className='page-body'>
      <UserList />
    </div>
  )
}

export default Users