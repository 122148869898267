/*
import { Breadcrumb, BreadcrumbItem, Col } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import H4 from "../Headings/H4Element";
import FeatherIcons from "../FeatherIcon/FeatherIcons";

const CommonBreadcrumb = () => {
  const location = useLocation();
  const [firstPart, secondPart, thirdPart] = location.pathname
    .split("/")
    .slice(1);
  console.log(firstPart, "firstPartfirstPart");

  const BreadcrumbData = () => {};

  return (
    <Col xs="4" xl="4" className="page-title">
      <H4 className="f-w-700 text-capitalize">
      </H4>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
            <FeatherIcons iconName="Home" />
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem className="f-w-400 text-capitalize">
          {}
          {firstPart.replaceAll("_", " ") === "certificate"
            ? "সনদ"
            : firstPart.replaceAll("_", " ")}
        </BreadcrumbItem>
        {secondPart && (
          <BreadcrumbItem className={`f-w-400 ${!thirdPart ? "active" : ""}`}>
            {secondPart.replaceAll("_", " ") === "list"
              ? "সনদ লিস্ট"
              : secondPart.replaceAll("_", " ")}
          </BreadcrumbItem>
        )}
        {thirdPart && (
          <BreadcrumbItem className="f-w-400 active">
            {thirdPart.replaceAll("_", " ")}
          </BreadcrumbItem>
        )}
      </Breadcrumb>
    </Col>
  );
};

export default CommonBreadcrumb;

*/

import { Breadcrumb, BreadcrumbItem, Col } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import H4 from "../Headings/H4Element";
import FeatherIcons from "../FeatherIcon/FeatherIcons";

const CommonBreadcrumb = () => {
  const location = useLocation();
  const [firstPart, secondPart, thirdPart] = location.pathname
    .split("/")
    .slice(1);

  const breadcrumbData = (segment: string) => {
    let result = "";
    if (segment === "first") {
      if (firstPart === "certificate") {
        result = "সনদ";
      }
      if (firstPart === "dashboard") {
        result = "হোম";
      }
      if (firstPart === "settings") {
        result = "সেটিংস";
      }
      if (firstPart === "user") {
        result = "ইউজার";
      }
    }
    if (segment === "second") {
      if (secondPart === "list") {
        result = "সনদ লিস্ট";
      }
      if (secondPart === "create") {
        result = "সনদ তৈরী";
      }
      if (secondPart === "edit") {
        result = "সনদ এডিট";
      }
      if (secondPart === "view") {
        result = "সনদ ভিউ";
      }
      if (secondPart === "report") {
        result = "সনদ রিপোর্ট";
      }
      if (secondPart === "unions") {
        result = "ইউনিয়ন লিস্ট";
      }
      if (secondPart === "union") {
        result = "ইউনিয়ন";
      }
      if (secondPart === "users") {
        result = "ইউজার লিস্ট";
      }
      if (secondPart === "user") {
        result = "ইউজার";
      }
      if (secondPart === "profile") {
        result = "প্রোফাইল";
      }
      if (secondPart === "settings") {
        result = "সেটিংস";
      }
    }

    if (segment === "third") {
      if (thirdPart === "create") {
        result = "তৈরী";
      }
    }
    return result;
  };

  return (
    <Col xs="4" xl="4" className="page-title">
      <H4 className="f-w-700 text-capitalize">
        {/* {thirdPart
          ? thirdPart.replaceAll("_", " ")
          : secondPart.replaceAll("_", " ")} */}
      </H4>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
            <FeatherIcons iconName="Home" />
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem className="f-w-400 text-capitalize">
          {breadcrumbData("first")}
        </BreadcrumbItem>
        {secondPart && (
          <BreadcrumbItem className={`f-w-400 ${!thirdPart ? "active" : ""}`}>
            {breadcrumbData("second")}
          </BreadcrumbItem>
        )}
        {thirdPart && (
          <BreadcrumbItem className="f-w-400 active">
            {breadcrumbData("third")}
          </BreadcrumbItem>
        )}
      </Breadcrumb>
    </Col>
  );
};

export default CommonBreadcrumb;
