import LoginWithValidationContainer from '../../../../Componant/OtherPages/Authentication/LoginWithValidation/LoginWithValidation'

const LoginWithValidation = () => {
  return (
    <div className='page-body'>
      <LoginWithValidationContainer />
    </div>
  )
}

export default LoginWithValidation