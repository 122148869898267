import ResetPasswordContainer from '../../../../Componant/OtherPages/Authentication/ResetPassword/ResetPassword'

const ResetPassword = () => {
  return (
    <div className='page-body'>
      <ResetPasswordContainer />
    </div>
  )
}

export default ResetPassword