import React from "react";
import moment from "moment";
import { Row, Col } from "reactstrap";
import parse from "html-react-parser";
import {
  banglaToEnglishNumber,
  englishToBanglaNumber,
} from "../../../utils/helpers";

const TradeLicenceEng = ({ certificate, tranlation }) => {
  const { details } = certificate || {};
  const taxAndFeeRows = () => {
    return (
      details?.taxesAndFees?.length &&
      details?.taxesAndFees[0]?.item !== "" &&
      details?.taxesAndFees?.map((data, key) => {
        return (
          <tr key={key}>
            <td>{++key}</td>
            <td>{data.item}</td>
            <td>{data.amount}</td>
          </tr>
        );
      })
    );
  };

  let totalFee =
    details?.taxesAndFees?.length > 0 &&
    details?.taxesAndFees.reduce((accumulator, currentValue) => {
      return (
        accumulator +
        (currentValue.amount !== "" ? parseInt(currentValue.amount) : 0)
      );
    }, 0);

  return (
    <>
      <Row className="mt-2 mb-2 certificate-row trade-licenc-title">
        <Col md={3}>Trade License: {certificate?.certificateNo}</Col>
        {details?.financeYear && (
          <Col md={3}>
            Finance Year: {banglaToEnglishNumber(details?.financeYear)}
          </Col>
        )}
        {certificate?.issueDate && (
          <Col>
            Issue Date: {moment(certificate?.issueDate).format("DD-MM-YYYY")}
          </Col>
        )}
        {details?.validDate && (
          <Col>
            Valid Date: {moment(details?.validDate).format("DD-MM-YYYY")}
          </Col>
        )}
      </Row>
      <Row className="trade-certificate-note">
        <Col>{`Under the powers conferred by Section 66 of the Local Government (Union Parishad) Act, 2009 (Act No. 61 of 2009) 
        and in accordance with Articles 6 and 17 of the Model Tax Schedule, 2013, this trade license is issued to the following 
        individual/establishment for the collection of taxes imposed on businesses, professions, occupations, or 
        industrial establishments under the specified conditions:`}</Col>
      </Row>
      {/* <h3>নাগরিক তথ্য:</h3> */}
      <Row className="trade-licence-top-area mt-1">
        <Col className="left-col">
          <Row>
            <Col sm={4}>Organization Name</Col>
            <Col>
              <span className="fw-bold">:&nbsp; {details?.instituteName}</span>
            </Col>
          </Row>
          {details?.typeOfBusiness && (
            <Row>
              <Col sm={4}>Type of Business</Col>
              <Col>
                <span>:&nbsp;{details?.typeOfBusiness}</span>
              </Col>
            </Row>
          )}
          {details?.typeOfNature && (
            <Row>
              <Col sm={4}>Nature of Business</Col>
              <Col>
                <span>:&nbsp;{details?.typeOfNature}</span>
              </Col>
            </Row>
          )}
          {details?.tax_region && (
            <Row>
              <Col sm={4}>Tax Zone</Col>
              <Col>
                <span>:&nbsp;{details?.tax_region}</span>
              </Col>
            </Row>
          )}
          {details?.ward_no && (
            <Row>
              <Col sm={4}>Ward No.</Col>
              <Col>
                <span>:&nbsp;{details?.ward_no}</span>
              </Col>
            </Row>
          )}
          {details?.capital && (
            <Row>
              <Col sm={4}>Capital</Col>
              <Col>
                <span>:&nbsp;{details?.capital}</span>
              </Col>
            </Row>
          )}
          {details?.instituteAddress && (
            <Row>
              <Col sm={4}>Organization's Address</Col>
              <Col>
                <span>:&nbsp;{details?.instituteAddress}</span>
              </Col>
            </Row>
          )}
        </Col>
        <Col className="right-col">
          {details?.applicantName && (
            <Row>
              <Col md={4}>License Holder</Col>
              <Col>
                : <span className="fw-bold">{details?.applicantName}</span>
              </Col>
            </Row>
          )}
          {details?.fathersName && (
            <Row>
              <Col md={4}>Father's Name</Col>
              <Col>
                <span>:&nbsp; {details?.fathersName}</span>
              </Col>
            </Row>
          )}
          {details?.mothersName && (
            <Row>
              <Col md={4}>Mother's Name</Col>
              <Col>
                <span>:&nbsp; {details?.mothersName}</span>
              </Col>
            </Row>
          )}
          {details?.mobileNo && (
            <Row>
              <Col md={4}>Mobile/Telephone</Col>
              <Col>
                <span>:&nbsp; {details?.mobileNo}</span>
              </Col>
            </Row>
          )}
          {details?.email && (
            <Row>
              <Col md={4}>E-mail</Col>
              <Col>
                <span>:&nbsp; {details?.email}</span>
              </Col>
            </Row>
          )}
          {details?.nid_passport_birth_reg_no && (
            <Row>
              <Col md={4}>NID/Passport</Col>
              <Col>
                <span>:&nbsp; {details?.nid_passport_birth_reg_no}</span>
              </Col>
            </Row>
          )}
          {details?.tin && (
            <Row>
              <Col md={4}>E-TIN</Col>
              <Col>
                <span>:&nbsp; {details?.tin}</span>
              </Col>
            </Row>
          )}
          {details?.presentAddress && (
            <Row>
              <Col md={4}>Present Address</Col>
              <Col>
                <span>:&nbsp; {details?.presentAddress}</span>
              </Col>
            </Row>
          )}
          {details?.permanentAddress && (
            <Row>
              <Col md={4}>Permanent Address</Col>
              <Col>
                <span>:&nbsp; {details?.permanentAddress}</span>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Row className="mb-1 mt-1">
        <Col>
          <b>Fees & Taxes:</b>
        </Col>
      </Row>
      <Row className="pb-4">
        <Col>
          <table className="list-table  my-0 trade-licence-table">
            <thead>
              <tr>
                <th>Serial No.</th>
                <th>Item Name</th>
                <th>Fee</th>
              </tr>
            </thead>
            <tbody>{taxAndFeeRows()}</tbody>
            <tfoot>
              {details?.taxesAndFees?.length > 0 && (
                <tr className="total-row">
                  <td colSpan={2}>Total</td>
                  <td className="text-center">{totalFee ? totalFee : ""}</td>
                </tr>
              )}
            </tfoot>
          </table>
        </Col>
      </Row>
    </>
  );
};

export default TradeLicenceEng;
