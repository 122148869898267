import UnionList from '../../../Componant/Tables/Settings/Unions'

const Unions = () => {
  return (
    <div className='page-body'>
      <UnionList />
    </div>
  )
}

export default Unions