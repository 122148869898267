import { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { H4, Image, P } from "../../../../../AbstractElements";
import { dynamicImage } from "../../../../../Service";
import CardHeaderCommon from "../../../../../CommonElements/CardHeaderCommon/CardHeaderCommon";
import { capitalizeFirst } from "../../../../../utils/helpers";
import { useSelector } from "react-redux";
import { getRequest } from "../../../../../utils/axiosRequests";

const EditMyProfile = () => {
  const userInfo = useSelector((state: any) => state.auth.data);
  const [balanceInfo, setBalanceInfo] = useState<any>({});

  const getAvailableBalance = async () => {
    await getRequest(`settings/users/user-balance`)
      .then((data) => {
        setBalanceInfo(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAvailableBalance();
  }, []);

  return (
    <Col xl="4">
      <Card>
        <CardHeaderCommon title={"My Profile"} tagClass="card-title mb-0" />
        <CardBody>
          <Row className="mb-2">
            <div className="profile-title">
              <div className="text-center">
                <Image
                  className="img-70 rounded-circle"
                  alt="edit-user"
                  src={dynamicImage("user/7.jpg")}
                />
                <div className="current-user-info">
                  <H4 className="my-1">{userInfo?.name}</H4>
                  <div>{capitalizeFirst(userInfo?.userType)}</div>
                  <div>{userInfo?.email}</div>
                  <div>{userInfo?.mobile}</div>
                  <div>{userInfo?.address}</div>
                  <div className="fw-bold">
                    {capitalizeFirst(userInfo?.unionId?.name)}
                  </div>
                  {userInfo?.userType === "user" && (
                    <>
                      <hr />
                      <div className="fw-bold">
                        <div className="fw-bold">
                          Available Balance: {balanceInfo?.availableBalance}
                        </div>
                        <div className="fw-bold">
                          Total Created Certificate: {balanceInfo?.totalCreated}
                        </div>
                        <div className="fw-bold">
                          Total Balance: {balanceInfo?.totalBalance}
                        </div>
                      </div>
                    </>
                  )}
                  {userInfo?.aboutMe && (
                    <>
                      <hr />
                      <p>{userInfo?.aboutMe}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Row>
          <hr />
        </CardBody>
      </Card>
    </Col>
  );
};

export default EditMyProfile;
