import { memo } from "react";
//import Barcode from "react-barcode";
//import QRCode from "react-qr-code";
import { QRCode } from "react-qrcode-logo";
import monogramImg from "../../../images/general/bdMonogram.png";
//import mujibImg from "../../../images/general/mujib.jpg";
//import { useSelector } from "react-redux";

const CertificateHeader = ({ certificate, tranlation }) => {
  const qrValue = `${certificate?.certificateNo},
  ${certificate?.details?.applicantName}`;

  return (
    <div className="pb-3 row header-row">
      <div className="col-sm-3">
        {/* <div
          style={{
            height: "auto",
            margin: "0 auto",
            maxWidth: 80,
            width: "100%",
            textAlign: "center",
          }}
          className="qr-code"
        > */}
        {certificate && certificate?.certificateNo && (
          <div style={{ width: "50px", height: "50px" }}>
            <QRCode
              //size={100}
              value={qrValue}
              eyeRadius={5} // 5 pixel radius for all corners of all positional eyes
            />
          </div>
        )}
        {/* {certificate && certificate?.certificateNo && (
          <QRCode
            size={270}
            style={{
              marginLeft: "10px",
              marginTop: "10px",
              height: "auto",
              maxWidth: "70%",
              width: "68%",
              textAlign: "center",
            }}
            value={qrValue}
            viewBox={`0 0 300 300`}
          />
        )} */}
        {/* </div> */}
        {/* <Barcode
          value={certificate?.certificateNo}
          width="2"
          height="40"
          format="CODE128"
          displayValue="false"
          fontOptions=""
          font="monospace"
          textAlign="center"
          textPosition="bottom"
          textMargin="2"
          fontSize="20"
          background="#ffffff"
          lineColor="#000000"
          margin="10"
          marginTop="undefined"
          marginBottom="undefined"
          marginLeft="undefined"
          marginRight="undefined"
        /> */}
      </div>
      <div
        className="col-sm-6 union-info"
        style={{ paddingLeft: 0, paddingRight: 0, lineHeight: "1.08rem" }}
      >
        <img src={monogramImg} height="80" width="80" />
        <div className="mt-1">
          {tranlation === "ENG"
            ? "People's Republic of Bangladesh"
            : "গণপ্রজাতন্ত্রী বাংলাদেশ সরকার"}
        </div>
        <div>
          {tranlation === "ENG"
            ? "07 No. Saturia Union Parishad"
            : certificate?.union?.name}
        </div>
        <div className="font-weight-bold">
          {tranlation === "ENG" ? "Saturia" : certificate?.union?.thana},
          {tranlation === "ENG" ? "Manikganj" : certificate?.union?.zilla}
        </div>
        <div>
          <div>
            {tranlation === "ENG" ? "E-mail" : "ই-মেইল"} :{" "}
            {certificate?.union?.email}
          </div>
        </div>
        {/* <div>মোবাইল: {certificate?.union?.mobileNo}</div> */}
      </div>
      <div className="col-sm-3 text-end">
        {/* <img src={mujibImg} height="80" width="80" /> */}
      </div>
    </div>
  );
};

export default memo(CertificateHeader);
