import { Card, CardBody, Col, Container, Row } from "reactstrap";
import CardHeaderCommon from "../../../../CommonElements/CardHeaderCommon/CardHeaderCommon";
import CertificatesFeeForm from "../../../../Componant/Forms/Settings/CertificatesFeeForm";
import { useLocation } from "react-router-dom";

const CertificatesFee = () => {
  const location = useLocation();
  const union = location?.state?.union;

  return (
    <div className="page-body">
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeaderCommon title={`সনদ ফি [${union?.name}]`} />{" "}
              <CardBody>
                <CertificatesFeeForm />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CertificatesFee;
