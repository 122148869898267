import { Link } from "react-router-dom";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H3 } from "../../AbstractElements";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getRequest } from "../../utils/axiosRequests";

const Verify = () => {
  const [certificateNo, setCertificateNo] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError("");
      }, 4000); // 3000 milliseconds = 3 seconds

      // Clear the timer if the component is unmounted or if the error changes
      return () => clearTimeout(timer);
    }
  }, [error]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const submitData = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // const englishRegex = /^[0-9]+$/;
    // const hasEnglish = englishRegex.test(certificateNo);
    // if (!hasEnglish) {
    //   toast.success("সনদ নং টাইপ করুন ইংরেজিতে", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   setError("সনদ নং টাইপ করুন ইংরেজিতে");
    // }
    await getRequest(
      `certificates/verify-certificate?certificateNo=${certificateNo}`
    )
      .then((data) => {
        if (data) {
          setError("আপনার সনদ অরিজিনাল");
          // toast.success("আপনার সনদ অরিজিনাল", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        } else {
          setError("আপনার সনদ নকল");
          // toast.error("আপনার সনদ নকল", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        }
      })
      .catch((error) => {
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
        //console.log("from react query error: ", error.message);
      });
  };

  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card login-dark">
            <div>
              {/* <div>
                <Link className="logo text-center" to={Href}>
                  <Image
                    className="img-fluid for-light"
                    src={dynamicImage("logo/logo.png")}
                    alt="looginpage"
                  />
                  <Image
                    className="img-fluid for-dark"
                    src={dynamicImage("logo/logo_dark.png")}
                    alt="looginpage"
                  />
                </Link>
              </div> */}
              <div className="login-main" style={{ paddingBottom: "25px" }}>
                <Form className="theme-form" onSubmit={(e) => submitData(e)}>
                  <H3 className="text-center f-w-600">সনদ যাচাই</H3>
                  <hr />
                  {error && (
                    <div className="text-center text-danger">{error}</div>
                  )}
                  <FormGroup>
                    <Label className="col-form-label"> সনদ নং</Label>
                    <Input
                      bsSize="sm"
                      type="text"
                      required
                      placeholder="সনদ নং টাইপ করুন ইংরেজিতে"
                      // {...register("certificateNo", {
                      //   required: true,
                      //   maxLength: 20,
                      // })}
                      onChange={(e) => setCertificateNo(e.target.value)}
                    />
                    {errors?.certificateNo?.type === "required" && (
                      <span className="error-msg">This field is required</span>
                    )}
                  </FormGroup>
                  <FormGroup className="mb-0 form-sub-title">
                    <div className="text-end mt-3">
                      <Btn color="primary" block className="w-100">
                        যাচাই
                      </Btn>
                    </div>
                  </FormGroup>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Verify;
