import Error401Container from '../../../../Componant/OtherPages/Error/Error401/Error401'

const Error401 = () => {
  return (
    <div className='page-body'>
      <Error401Container />
    </div>
  )
}

export default Error401