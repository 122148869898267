import moment from "moment";
import { Row, Col } from "reactstrap";
import parse from "html-react-parser";
import { englishToBanglaNumber, numberArray } from "../../../utils/helpers";

const UttoradhikarSonod = ({ certificate }) => {
  const { details } = certificate || {};

  const relationRows =
    certificate?.details?.relatives?.length &&
    certificate?.details?.relatives.map((data, key) => {
      return (
        <tr key={key}>
          <td style={{ width: "15%" }}>{numberArray[key]}</td>
          <td style={{ width: "55%" }}>{data.name}</td>
          <td>{data?.relationship}</td>
        </tr>
      );
    });

  return (
    <>
      <div className="character-sonod left-title">
        <Row className="mt-3 mb-4 certificate-row">
          <Col className="sonod-no">
            সনদ নং: {englishToBanglaNumber(certificate?.certificateNo)}
          </Col>
          <Col className="text-end issue-date">
            ইস্যু তারিখ:{" "}
            {englishToBanglaNumber(
              moment(certificate?.issueDate).format("DD-MM-YYYY")
            )}
          </Col>
        </Row>
        <h3>নাগরিক তথ্য:</h3>
        <Row>
          <Col sm={3}>নাম</Col>
          <Col sm={8}>
            : <span>{details?.applicantName}</span>
          </Col>
        </Row>
        {details?.fathersName && (
          <Row>
            <Col sm={3}>পিতার নাম</Col>
            <Col sm={8}>
              : <span>{details?.fathersName}</span>
            </Col>
          </Row>
        )}
        {details?.husbandName && (
          <Row>
            <Col sm={3}>স্বামীর নাম</Col>
            <Col sm={8}>
              : <span>{details?.husbandName}</span>
            </Col>
          </Row>
        )}
        {details?.birthCertificateNo && (
          <Row>
            <Col sm={3}>জন্ম নিবন্ধন নম্বর</Col>
            <Col sm={8}>
              : <span>{details?.birthCertificateNo}</span>
            </Col>
          </Row>
        )}
        {details?.nid && (
          <Row>
            <Col sm={3}>জাতীয় পরিচয় পত্র নম্বর</Col>
            <Col sm={8}>
              : <span>{details?.nid}</span>
            </Col>
          </Row>
        )}
        {details?.holdingNo && (
          <Row>
            <Col sm={3}>হোল্ডিং</Col>
            <Col sm={8}>
              : <span>{details?.holdingNo}</span>
            </Col>
          </Row>
        )}
        <Row>
          <Col sm={3}>স্থায়ী ঠিকানা</Col>
          <Col sm={8}>
            : <span>{details?.applicantAddress}</span>
          </Col>
        </Row>
      </div>
      <Row className="certificate-note">
        <Col>{parse(certificate?.certificateNote)}</Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <table className="my-0 list-table">
            <thead>
              <tr>
                <th>ক্রমিক নং</th>
                <th>নাম</th>
                <th>সম্পর্ক</th>
              </tr>
            </thead>
            <tbody>{relationRows}</tbody>
          </table>
        </Col>
      </Row>
      <Row className="pb-3 mb-2 certificate-note">
        <Col>
          আমি মরহুমের বিদেহী আত্মার মাগফিরাত কামনা করছি এবং তাঁর পরিবারের সকলের
          এই দুঃখজনক ক্ষতি ও শোক সহ্য করার জন্য, সর্বশক্তিমান আল্লাহর কাছে দোয়া
          করছি।
        </Col>
      </Row>
    </>
  );
};

export default UttoradhikarSonod;
