import { useEffect } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import ReactDatePicker from "react-datepicker";
import { numberArray } from "./../../../utils/helpers";
import MyEditor from "../../Forms/FormsControl/FormsValidation/TooltipFormValidation/MyEditor";

const UttoradhikarSonod = ({
  type,
  certificateNote,
  setCertificateNote,
  setValue,
  isNid,
  setIsNid,
  isHusband,
  setIsHusband,
  relatives,
  setRelatives,
  register,
  watch,
  errors,
  serverError,
}) => {
  const certificateNo = watch("certificateNo");
  const issueDate = watch("issueDate");
  const birthDate = watch("details.birthDate");
  const applicantName = watch("details.applicantName");
  const fathersName = watch("details.fathersName");
  const mothersName = watch("details.mothersName");
  const husbandName = watch("details.husbandName");
  const applicantAddress = watch("details.applicantAddress");
  const nid = watch("details.nid");
  const birthCertificateNo = watch("details.birthCertificateNo");
  const holdingNo = watch("details.holdingNo");

  useEffect(() => {
    setValue("issueDate", issueDate ? issueDate : new Date());
  }, []);

  const handleRelatives = (filedName, key, val) => {
    relatives[key][filedName] = val;
    setRelatives([...relatives]);
  };

  const handleRow = (action, key) => {
    if (action === "add") {
      setRelatives([...relatives, { name: "", relationship: "" }]);
    } else {
      setRelatives(relatives.filter((item, index) => index !== key));
    }
  };

  const relationshipRows =
    relatives?.length &&
    relatives?.map((data, key) => {
      let count = key;
      return (
        <tr key={key}>
          <td>{numberArray[count]}</td>
          <td>
            <input
              type="text"
              className="form-control form-control-sm"
              defaultValue={data.name}
              onChange={(e) => handleRelatives("name", key, e.target.value)}
            />
          </td>
          <td>
            <input
              type="text"
              className="form-control form-control-sm"
              defaultValue={data.relationship}
              onChange={(e) =>
                handleRelatives("relationship", key, e.target.value)
              }
            />
          </td>
          <td className="certificate-action">
            <i
              className="fa fa-plus-circle custom-icon"
              onClick={() => handleRow("add")}
            ></i>
            {relatives.length > 1 && (
              <i
                className="fa fa-times-circle text-danger custom-icon"
                style={{ paddingLeft: "6px" }}
                onClick={() => handleRow("delete", key)}
              ></i>
            )}
          </td>
        </tr>
      );
    });

  return (
    <>
      <Row className="mb-3">
        <Col md="4" className="position-relative">
          <Label>সনদ নং:</Label>
          <Input
            type="text"
            bsSize="sm"
            className={`${errors?.certificateNo ? "is-invalid" : ""}`}
            {...register("certificateNo", { required: true })}
            value={certificateNo}
            disabled
          />
          {errors.certificateNo && (
            <span className="error-msg">This field is required</span>
          )}
        </Col>
        <Col md="4" className="position-relative">
          <Label>ইস্যু তারিখ:</Label>
          <ReactDatePicker
            className="form-control form-control-sm flatpickr-input"
            {...register("issueDate", { required: true })}
            selected={issueDate}
            onChange={(date) => setValue("issueDate", date)}
            dateFormat="dd-MM-yyyy"
          />
          {errors.issueDate && (
            <span className="error-msg">This field is required</span>
          )}
        </Col>
        <Col md="4" className="position-relative">
          <Label>
            নাম:<sup className="text-danger">*</sup>
          </Label>
          <Input
            bsSize="sm"
            type="text"
            className={`${errors?.details?.applicantName ? "is-invalid" : ""}`}
            {...register("details.applicantName", { required: true })}
            defaultValue={applicantName}
            onChange={(e) => {
              setValue("details.applicantName", e.target.value);
            }}
          />
          {errors?.details?.applicantName && (
            <span className="error-msg">This field is required</span>
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="4" className="position-relative">
          <Label style={{ paddingRight: "6px" }}>
            <input
              type="radio"
              name="isHusband"
              checked={isHusband === true}
              onChange={(e) => setIsHusband(true)}
            />{" "}
            স্বামীর নাম
          </Label>
          <Label style={{ paddingLeft: "6px" }}>
            <input
              type="radio"
              className="mr-2"
              name="isHusband"
              checked={isHusband === false}
              onChange={(e) => setIsHusband(false)}
            />{" "}
            পিতার নাম
          </Label>
          {!isHusband && (
            <>
              <Input
                bsSize="sm"
                type="text"
                placeholder=" পিতার নাম"
                className={`${
                  errors?.details?.fathersName ? "is-invalid" : ""
                }`}
                {...register("details.fathersName", {})}
                defaultValue={fathersName}
                onChange={(e) => {
                  setValue("details.fathersName", e.target.value);
                }}
              />
              {errors?.details?.fathersName && (
                <span className="error-msg">This field is required</span>
              )}
            </>
          )}
          {isHusband && (
            <>
              <Input
                bsSize="sm"
                type="text"
                placeholder=" স্বামীর নাম"
                className={`${
                  errors?.details?.husbandName ? "is-invalid" : ""
                }`}
                {...register("details.husbandName", {})}
                defaultValue={husbandName}
                onChange={(e) => {
                  setValue("details.husbandName", e.target.value);
                }}
              />
              {errors?.details?.husbandName && (
                <span className="error-msg">This field is required</span>
              )}
            </>
          )}
        </Col>
        <Col md="4" className="position-relative">
          <Label style={{ paddingRight: "6px" }}>
            <input
              type="radio"
              className="mr-2"
              name="isNid"
              checked={isNid === false}
              onChange={(e) => setIsNid(false)}
            />{" "}
            জন্ম নিবন্ধন নম্বর{" "}
          </Label>
          <Label style={{ paddingLeft: "6px" }}>
            <input
              type="radio"
              name="isNid"
              checked={isNid === true}
              onChange={(e) => setIsNid(true)}
            />{" "}
            জাতীয় পরিচয় পত্র নম্বর
          </Label>
          {!isNid && (
            <>
              <Input
                bsSize="sm"
                type="text"
                placeholder=" জন্ম নিবন্ধন নম্বর"
                className={`${
                  errors?.details?.birthCertificateNo ? "is-invalid" : ""
                }`}
                {...register("details.birthCertificateNo", {})}
                defaultValue={birthCertificateNo}
                onChange={(e) => {
                  setValue("details.birthCertificateNo", e.target.value);
                }}
              />
              {errors?.details?.birthCertificateNo && (
                <span className="error-msg">This field is required</span>
              )}
            </>
          )}
          {isNid && (
            <>
              <Input
                bsSize="sm"
                type="text"
                placeholder="জাতীয় পরিচয় পত্র নম্বর"
                className={`${errors?.details?.nid ? "is-invalid" : ""}`}
                {...register("details.nid", {})}
                defaultValue={nid}
                onChange={(e) => {
                  setValue("details.nid", e.target.value);
                }}
              />
              {errors?.details?.nid && (
                <span className="error-msg">This field is required</span>
              )}
            </>
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs="12">
          <Label>স্থায়ী ঠিকানা:</Label>
          <Input
            bsSize="sm"
            type="text"
            className={`${
              errors?.details?.applicantAddress ? "is-invalid" : ""
            }`}
            {...register("details.applicantAddress", { required: true })}
            defaultValue={applicantAddress}
            onChange={(e) => {
              setValue("details.applicantAddress", e.target.value);
            }}
          />
          {errors?.details?.applicantAddress && (
            <span className="error-msg">This field is required</span>
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <MyEditor
            certificateNote={certificateNote}
            setCertificateNote={setCertificateNote}
          />
        </Col>
      </Row>
      <Row className="mb-1">
        <Col>
          <table className=" my-0 uttoradhirkar list-table">
            <thead>
              <tr className="fw-bold text-center">
                <th>ক্রমিক নং</th>
                <th>নাম</th>
                <th>সম্পর্ক</th>
                <th className="">Action</th>
              </tr>
            </thead>
            <tbody>{relationshipRows}</tbody>
          </table>
        </Col>
      </Row>
    </>
  );
};

export default UttoradhikarSonod;
